@tailwind base;
@tailwind components;
@tailwind utilities;

.atropos-scale {
  pointer-events: none;
}

.atropos-rotate {
  pointer-events: all;
}
